const config = {
    host: (process.env.NODE_ENV === 'production') ? 'http://192.168.1.106:3016' : 'http://192.168.1.104:3021',
    homePage: (process.env.NODE_ENV === 'production') ? 'http://192.168.1.106:3016' : 'http://192.168.1.104:3021',
    api: 'api/',
    apiSocket: 'fastapp::1.2',
    nameDir: 'fastapp',
    package: 'ec.com.fastapp.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyDf_VWL945mw08Q4ZI3ZYmnIF2Vk9_GFdQ',
    appName: 'FastApp',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#1A2959',
    colorDark: '#1A1A1A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'ec.com.fastapp.passenger',
    playStoreDriverId: 'ec.com.fastapp.driver',
    appStorePassengerId: '6456751984',
    appStoreDriverId: '6456752170',
    email: "hperez@fastapp.com.ec",
};
export default config;
